<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="SettingsIcon" />
          <span>Servis Randevusu</span>
        </template>
        <loading v-if="dataLoading" />
        <service-view
          v-else
          :refresh-data="getServiceDetail"
        />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="ClipboardIcon" />
          İş Emirleri
        </template>
        <service-works />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Müşteri Geçmişi</span>
        </template>
        <customer />
      </b-tab>
    </b-tabs>
    <add-car :refresh-data="getServiceDetail" />
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
} from 'bootstrap-vue'

import Customer from '@/views/Customers/CustomerView.vue'
import ServiceView from '@/views/Service/View/ServiceView.vue'
import AddCar from '@/views/Service/components/AddCar.vue'
import ServiceWorks from '@/views/Service/View/ServiceWorks.vue'
import Loading from '@/layouts/components/Loading.vue'

// import KvkkModal from '@/layouts/components/common/KvkkModal.vue'

export default {
  name: 'View',
  components: {
    Loading,
    BTabs,
    BTab,
    Customer,
    ServiceView,
    AddCar,
    // KvkkModal,
    ServiceWorks,
  },
  computed: {
    service() {
      return this.$store.getters['serviceTracing/getService']
    },
    saveStatus() {
      return this.$store.getters['serviceTracing/getServiceSaveStatus']
    },
    dataLoading() {
      return this.$store.getters['serviceTracing/getDataLoading']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.getServiceDetail()
    },
  },
  beforeCreate() {
    this.$store.commit('customers/RESET_KVKK_SMS_RESULT')
    this.$store.commit('serviceTracing/RESET_ITEM_DATA')
  },
  created() {
    this.getServiceDetail()
  },
  methods: {
    getServiceDetail() {
      this.$store.dispatch('serviceTracing/serviceView', this.$route.params.id)
        .then(res => {
          if (res.id_com_cars) {
            this.$store.dispatch('cars/carView', res.id_com_cars)
          }
          if (res.id_com_customer) {
            this.$store.dispatch('customers/customerView', res.id_com_customer)
              .then(cusResp => {
                if (cusResp.id_com_city) {
                  this.$store.dispatch('districts/districtsList', {
                    select: ['com_district.id AS id', 'com_district.title AS title'],
                    where: {
                      id_com_city: cusResp.id_com_city,
                    },
                  })
                }
              })
          }
          if (res.id_com_brand === '14') {
            this.$store.dispatch('bcsCampaign/bcsSMSControl', {
              id_com_service_tracing: res.id,
            })
            this.$store.dispatch('bcsCampaign/bcsReferenceControl', {
              id_com_customer: res.id_com_customer,
              id_com_service_tracing: res.id,
            })
          }
        })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      }).then(resoponse => {
        if (resoponse.isConfirmed) {
          this.$router.push('/service')
        }
      })
    },
  },
}
</script>
